import { SYSTEM_ROLES } from "@/app/components/constants";
import { retryAuthentication } from "@/app/integration/microsoft";
import { retrySync } from "@/app/utils/StateSyncManager/UtilsSyncState";
import { fetchManagedTeams, logger } from "@/app/utils/Utils";
import { STORE } from "@/store";
import { DynamicLayout } from "@uuip/unified-ui-platform";
import { html } from "lit-html";
const AgentProfileBlock = () => {
    logger.info("Agent Profile Block is rendered:");
    const profileObj = {
        agentname: STORE.agent.agentName,
        agentid: STORE.agent.agentId,
        teamname: STORE.agent.teamName,
        teamid: STORE.agent.teamId,
        profileType: STORE.agent.profileType,
        channels: {
            voicecount: STORE.agent.channels.voiceCount,
            chatcount: STORE.agent.channels.chatCount,
            emailcount: STORE.agent.channels.emailCount,
            socialcount: STORE.agent.channels.socialCount
        },
        dnnumber: STORE.agent.dnNumber,
        agentstatus: STORE.agent.subStatus,
        tasklistlength: STORE.agentContact.taskMap.size,
        darktheme: STORE.app.darkMode,
        themeswitchenabled: STORE.app.darkThemeEnabled,
        keyboardShortcutKeyConflict: STORE.shortcutKeys.updateKeyboardShortcutConflict,
        agentphoto: STORE.agent.agentPhoto,
        selectedRoleName: STORE.agent.userSelectedRole,
        isExtension: STORE.agent.isExtension,
        usesOtherDN: STORE.agent.usesOtherDN,
        userRole: STORE.agent.userRole,
        monitoringEnabled: STORE.agent.isMonitoringEnabled,
        isManagedTeamsEnabled: STORE.agent.isManagedTeamsEnabled,
        managedTeams: STORE.agent.managedTeams,
        agentDbId: STORE.agent.agentAnalyzerId,
        engagedState: STORE.agentContact.getEngagedStatus,
        isWebRTCEnabled: (STORE.app.featureFlags.isWebRTCEnabled && STORE.agent.role === SYSTEM_ROLES.AGENT) ||
            (STORE.app.featureFlags.isWebRTCEnabled &&
                STORE.app.featureFlags.isWebRTCSupervisorEnabled &&
                STORE.agent.role === SYSTEM_ROLES.SUPERVISOR),
        extensionNumber: STORE.agent.extensionNumber,
        dialNumberValueForNonUS: STORE.agent.dialNumberValueForNonUS,
        defaultDn: STORE.agent.defaultDn,
        isDefaultDnSet: STORE.agent.isDefaultDnSet,
        isManagedTeamLoading: STORE.agent.isManagedTeamLoading,
        isManagedTeamsError: STORE.agent.isManagedTeamsError,
        isMicAccessDenied: STORE.agent.getMicrophoneAccessDenied,
        deviceType: STORE.agent.deviceType,
        selectedInputDeviceId: STORE.agent.selectedInputDeviceId,
        selectedOutputDeviceId: STORE.agent.selectedOutputDeviceId,
        loginVoiceOptions: STORE.agent.loginVoiceOptions,
        webRtcStatus: STORE.agent.webRtcStatus,
        microsoftAuthConfig: STORE.auth.getMicrosoftAuthConfig,
        presenceServiceStatus: STORE.agent.presenceServiceStatus,
        collabDetails: STORE.agent.collaboration,
        isDesktopEmergencyNotificationEnabled: STORE.app.featureFlags.isDesktopEmergencyNotificationEnabled,
        isEmergencyModalAlreadyDisplayed: STORE.agent.isEmergencyModalAlreadyDisplayed,
        isSuppressWebexCallingNotificationEnabled: STORE.app.featureFlags.isSuppressWebexCallingNotificationEnabled
    };
    return html `
    <div class="agent-profile">
      <agentx-react-agent-profile
        .profileProps="${profileObj}"
        @ax-keyboard-shortcuts="${(e) => {
        STORE.shortcutKeys.updateShortcutKeysModal(e.detail);
    }}"
        @ax-toast-notification-settings="${(e) => {
        STORE.generalNotifications.updateNotificationSettings(e.detail.notificationEnabled, e.detail.silentNotificationEnabled);
    }}"
        @ax-reset-layout="${() => DynamicLayout.Element.resetAll()}"
        @ax-dark-theme="${(e) => STORE.app.toggleDarkMode(e.detail.darkTheme)}"
        @ax-change-team="${(e) => {
        STORE.app.changeAgentTeam(e.detail.selectedTeamDetails);
    }}"
        @ax-change-team-cancelled="${(e) => {
        STORE.session.errorModal.setErrorDetails(e);
    }}"
        @ax-update-profile="${(e) => {
        STORE.app.updateProfileSettings(e.detail.profileDetails);
        // close shortcutkey modal on update profile
        STORE.shortcutKeys.updateShortcutKeysModal(false);
        STORE.agent.updateSignOutByChangeProfile(true);
    }}"
        @ax-update-profile-cancelled="${(e) => {
        STORE.session.errorModal.setErrorDetails(e);
    }}"
        @update-flag-user-prefs-saved="${(e) => {
        STORE.agent.updateIsPreferenceSaved(e.detail.isPrefSaved);
    }}"
        @save-update-user-prefs-failed="${(e) => {
        STORE.agent.updateIsPreferenceSavedFailed(e.detail.isPrefSaved);
    }}"
        @update-user-preference-storage="${(e) => {
        STORE.app.saveUserPreferenceInSessionStorage(e.detail);
    }}"
        @ax-output-device-change="${(e) => {
        STORE.agent.updateSelectedOutputDeviceId(e.detail.selectedOutputDeviceId);
    }}"
        @ax-input-device-change="${(e) => {
        STORE.agent.updateSelectedInputDeviceId(e.detail.selectedInputDeviceId);
    }}"
        @ax-speaker-mic-setting-update="${(e) => {
        STORE.agent.updateMediaSettings(e.detail.speakerMicSettings);
    }}"
        @initiate-microsoft-auth-flow="${() => {
        retryAuthentication();
    }}"
        @retry-presence-sync-register="${() => {
        retrySync();
    }}"
        @fetch-managed-team=${() => fetchManagedTeams()}
        @ax-aqm-signout-by-ui="${(e) => {
        STORE.agent.updateSignOutByUi(e.detail.isSignedOut);
        STORE.agent.updateSignOutByChangeProfile(false);
    }}"
        agentname="${STORE.agent.agentName}"
      ></agentx-react-agent-profile>
    </div>
  `;
};
export default AgentProfileBlock;
