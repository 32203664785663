import { STORE } from "@/store";
import { ToasterNotificationItemContent } from "@uuip/unified-ui-platform-common-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import * as AiAssistantConstants from "./AiAssistantConstants";
import { InboundAiAssistantError, InboundRequestType } from "./MessageHandler";
class AiAssistantMessageProcessor {
    constructor() {
        this.aiAssistantNotifications = [];
        this.wellnessBreakNotifications = [];
    }
    process(processParams) {
        switch (processParams.inboundRequestType) {
            case InboundRequestType.ShowCallDropAiNotification:
                if (processParams.notificationLinkHandlerCallback) {
                    this.showCallDropAiNotification(processParams.notificationLinkHandlerCallback);
                }
                break;
            case InboundRequestType.RemoveCallDropAiNotifications:
                this.removeCallDropAiNotifications();
                break;
            case InboundRequestType.ShowWellnessBreakNotification:
                if (processParams.notificationAcceptActionCallback &&
                    processParams.notificationRejectActionCallback &&
                    processParams.notificationDismissActionCallback &&
                    processParams.notificationTimeoutCallback) {
                    this.showWellnessBreakNotification(processParams.notificationAcceptActionCallback, processParams.notificationRejectActionCallback, processParams.notificationDismissActionCallback, processParams.notificationTimeoutCallback);
                }
                break;
            case InboundRequestType.RemoveWellnessBreakNotifications:
                this.removeWellnessBreakNotifications(Notifications.ItemMeta.DeactivateEventReason.UserNeutral);
                break;
            case InboundRequestType.ShowWellnessBreakErrorNotification:
                if (processParams.errorType) {
                    this.showWellnessBreakErrorNotification(processParams.errorType);
                }
                break;
            default:
                break;
        }
    }
    showWellnessBreakNotification(acceptActionCallback, rejectActionCallback, dissmissActionCallback, timeoutCallback) {
        var _a;
        const wellnessBreakNotificationRaw = {
            type: Notifications.ItemMeta.Type.Default,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: AiAssistantConstants.WELL_BEING_BREAK_TITLE,
            data: new ToasterNotificationItemContent.DataController({
                text: AiAssistantConstants.WELL_BEING_BREAK_MESSAGE,
                type: "ai-assistant",
                actions: [
                    {
                        label: AiAssistantConstants.LATER,
                        handler: (_n) => {
                            rejectActionCallback();
                            this.removeWellnessBreakNotifications(Notifications.ItemMeta.DeactivateEventReason.UserNegative);
                        },
                        variant: "secondary"
                    },
                    {
                        label: AiAssistantConstants.TAKE_A_BREAK,
                        handler: (_n) => {
                            acceptActionCallback();
                            this.removeWellnessBreakNotifications(Notifications.ItemMeta.DeactivateEventReason.UserPositive);
                        },
                        variant: "primary"
                    }
                ],
                dismissHandler: (_n) => {
                    dissmissActionCallback();
                }
            })
        };
        const newWellnessBreakNotification = (_a = STORE.generalNotifications
            .fireNotificationEvent(wellnessBreakNotificationRaw)) === null || _a === void 0 ? void 0 : _a.at(0);
        if (newWellnessBreakNotification) {
            this.wellnessBreakNotifications = [...this.wellnessBreakNotifications, newWellnessBreakNotification];
            // Event listener for auto-dismiss
            newWellnessBreakNotification.addEventListener("statusUpdate", (status, reason) => {
                if (status === Notifications.ItemMeta.Status.Pended &&
                    reason === Notifications.ItemMeta.PendingEventReason.ServiceAutoDismiss) {
                    timeoutCallback();
                }
            });
        }
    }
    removeWellnessBreakNotifications(removeReason) {
        this.wellnessBreakNotifications.forEach((notification) => {
            notification.deactivate(removeReason);
        });
        this.wellnessBreakNotifications = [];
    }
    showCallDropAiNotification(openAiAssistantCallback) {
        var _a;
        const aiAssistantNotificationRaw = {
            type: Notifications.ItemMeta.Type.Default,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: AiAssistantConstants.CALL_SUMMARY_TITLE,
            data: new ToasterNotificationItemContent.DataController({
                text: AiAssistantConstants.CALL_SUMMARY_MESSAGE,
                type: "ai-assistant",
                linkName: AiAssistantConstants.CALL_SUMMARY_LINK,
                linkHandler: (_n) => {
                    openAiAssistantCallback();
                }
            })
        };
        const newAiAssistantNotification = (_a = STORE.generalNotifications
            .fireNotificationEvent(aiAssistantNotificationRaw)) === null || _a === void 0 ? void 0 : _a.at(0);
        if (newAiAssistantNotification) {
            this.aiAssistantNotifications = [...this.aiAssistantNotifications, newAiAssistantNotification];
        }
    }
    removeCallDropAiNotifications() {
        this.aiAssistantNotifications.forEach((notification) => {
            notification.deactivate(Notifications.ItemMeta.DeactivateEventReason.UserNeutral);
        });
        this.aiAssistantNotifications = [];
    }
    showWellnessBreakErrorNotification(errorType) {
        var _a;
        const getErrorTextByType = (errorType) => {
            switch (errorType) {
                case InboundAiAssistantError.ChangeStateToAvailableError:
                    return AiAssistantConstants.WELL_BEING_BREAK_ERROR_AVAILABLE;
                case InboundAiAssistantError.ChangeStateToResetError:
                    return AiAssistantConstants.WELL_BEING_BREAK_ERROR_IDLE;
                case InboundAiAssistantError.GeneralError:
                    return AiAssistantConstants.WELL_BEING_BREAK_GENERAL_ERROR;
                default:
                    return "";
            }
        };
        const wellnessStatusErrorNotificationRaw = {
            type: Notifications.ItemMeta.Type.Error,
            mode: Notifications.ItemMeta.Mode.AutoDismiss,
            title: AiAssistantConstants.WELL_BEING_BREAK_ERROR_TITLE,
            data: new ToasterNotificationItemContent.DataController({
                text: getErrorTextByType(errorType)
            })
        };
        const wellnessStatusErrorNotification = (_a = STORE.generalNotifications
            .fireNotificationEvent(wellnessStatusErrorNotificationRaw)) === null || _a === void 0 ? void 0 : _a.at(0);
        if (wellnessStatusErrorNotification) {
            this.wellnessBreakNotifications = [...this.wellnessBreakNotifications, wellnessStatusErrorNotification];
        }
    }
}
export default AiAssistantMessageProcessor;
