var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import "@/app/components/ConnectionLost";
import "@/app/components/CssDynamic";
import { createAgentXAppRouting } from "@/app/routing/routing";
import { i18nMixin, t } from "@/mixins/i18nMixin";
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { initSharedStoreActions } from "@/store/shared-actions";
import { MobxLitElement } from "@adobe/lit-mobx";
import "@agentx/agentx-mfe-react-based";
import "@agentx/agentx-mfe-wc-based";
import { ScreenPop, analyzerSessionHelper, initAgentxMfeWcBased } from "@agentx/agentx-mfe-wc-based";
import { SERVICE, initAgentxServices, updateAccessToken } from "@agentx/agentx-services";
import wxmLogo from "@img/wxm.svg";
import "@momentum-ui/web-components";
import { CI } from "@uuip/unified-ui-platform";
import "@uuip/unified-ui-platform-common-components";
import "@uuip/uuip-advance-layout";
import { customElement, html, internalProperty } from "lit-element";
import { nothing } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import style from "./App.scss";
import AiAssistantMessageProcessor from "./components/AiAssistantMessageProcessor";
import "./components/ChangeTeamLoading";
import "./components/EmpyResponsiveWidget";
import "./components/GlobalLiveAnnouncer";
import MessageHandler from "./components/MessageHandler";
import "./components/QualityMonitoringWidgetWrapper";
import "./components/UpdateProfileSettingsLoading";
import "./components/WebCallingWidget";
import { AGENT_SIGNOUT_RESULT, AGENT_STATE_CHANGE_FAILED, COLLAB_TOOL, PRESENCE_SYNC, SYSTEM_ROLES, TOGGLE_VOICE_INTERACTION_PANEL, USER_ROLES, WEB_RTC_STATUS } from "./components/constants";
import { handleMicrosoftAuthFlow } from "./integration/microsoft";
import { fetchAndHandleWebexConfig } from "./integration/webex";
import { UserRoles, getLayoutConfigFromCache, processCachedLayoutJson, processJSONLayout } from "./utils/DesktopLayoutUtils";
import { IdleActivity } from "./utils/IdleActivity/IdleActivity";
import { StateSyncManager } from "./utils/StateSyncManager/StateSyncManager";
import { presenceRequestListeners, removeMercuryListeners } from "./utils/StateSyncManager/UtilsSyncState";
import { checkAgentLogout, dispatchUpdatedAgentProfileEvent, fetchManagedQueues, fetchManagedTeams, fetchMeDetails, findAuthTokenForIframeApp, getCanaryParam, getErrorMsgForReasonCode, getManagedOrgDetails, getOrgIdParam, getRoleNameForTelemetry, getUserSystemRole, handleLoginError, handleLogout, invokeLoginErrorModal, invokeTeamDeletedErrorModal, isCanaryFlagInQueryParam, isIframedLogoutRequested, isOnlySupervisor, listenScreenPopEvent, logNtpTime, multipleExist, registerDefaultShortcutKeys, registerWebRTCShortcuts, removeUnloadEvent, setQueryparams, tabClosePrompt, toggleVoiceInteractionPanel, triggerAgentSignoutResultNotification, triggerAgentStateErrorNotification, triggerWebRTCErrorNotification, triggerWebRTCSuccessNotification, unRegisterWebexSDK, updateAnalyzerSettings, updateFeatureFlags, updateIMISettings, updateOrgConfigs, updateOutdialAniList } from "./utils/Utils";
import { registerWebCalling } from "./utils/UtilsWebCalling";
import { subscribeToWebexEvents } from "./utils/UtilsWebex";
const logger = createLogger("[App]");
const IPAD_LANDSCAPE_LOWER_LIMIT = 1024;
const MOMENTUM_V2 = "momentumV2";
const LUMOS = "lumos";
const isMSStateSyncEnabled = () => { var _a, _b; return STORE.app.featureFlags.isDesktopMSStateSyncEnabled && ((_b = (_a = SERVICE.conf.profile) === null || _a === void 0 ? void 0 : _a.microsoftConfig) === null || _b === void 0 ? void 0 : _b.stateSynchronizationMS); };
const isWebexStateSyncEnabled = () => { var _a, _b; return STORE.app.featureFlags.isDesktopWebexStateSyncEnabled && ((_b = (_a = SERVICE.conf.profile) === null || _a === void 0 ? void 0 : _a.webexConfig) === null || _b === void 0 ? void 0 : _b.stateSynchronizationWebex); };
let App = class App extends i18nMixin(MobxLitElement) {
    constructor() {
        super(...arguments);
        this.isInitialized = false;
        this.isIframe = window.self !== window.top;
        this.isLoggedIn = false;
        this.isConnectionLost = false;
        this.isRestoreFailed = false;
        this.isSessionExpired = false;
        this.isrequestedIframeLogin = this.isIframe;
        this.isrequestedIframeLogout = false;
        this.isMultiLogin = false;
        this.isNotifsSuccess = false;
        this.isWebexCallingDeviceRegistered = false;
        this.isNoVoiceOptions = false;
        /**
         * Init Routing
         */
        this.agentXAppRouting = createAgentXAppRouting(STORE);
        this.idleActivity = null;
        /* Collapse Task List when Screen is resized to < 1024 as aux panel will get expanded */
        this.shouldCollapseTaskListWhenResized = () => {
            return (window.innerWidth < IPAD_LANDSCAPE_LOWER_LIMIT &&
                !STORE.app.taskAreaCollapsed &&
                !STORE.app.auxilliaryPanelCollapsed);
        };
        /* Resize functionality for rendering Aux panel as overlay < 1024 and removing overlay >=1024 */
        this.onResize = () => {
            const showAuxPanelAsOverlay = window.innerWidth < IPAD_LANDSCAPE_LOWER_LIMIT ? true : false;
            STORE.app.setShowAuxPanelAsOverlay(showAuxPanelAsOverlay);
            if (this.shouldCollapseTaskListWhenResized()) {
                STORE.app.toggleTaskAreaCollapse();
            }
        };
        this.shouldReloadForNewVersion = () => {
            const isCanaryFlag = getCanaryParam();
            const isCanaryFlagAlreadyInQueryParam = isCanaryFlagInQueryParam();
            return isCanaryFlag && !isCanaryFlagAlreadyInQueryParam;
        };
        this.processIframeAuthMessage = (event) => {
            const { type, config } = event.data;
            if (type === "agentx-login-from-iframe") {
                logger.info("event=IFrameLogin Received auth token detail for Iframe app");
                if (config) {
                    const { ci_config, token, uuipAuthTokens, ciClusterId } = config;
                    if (ci_config && ci_config.identityUrl && ci_config.idbrokerUrl) {
                        const { identityUrl, idbrokerUrl } = ci_config;
                        STORE.auth.updateCiDetails(identityUrl, idbrokerUrl);
                        logger.info("Taking FLS identity URL from cache");
                        localStorage.setItem("uuip-auth-token", JSON.stringify(uuipAuthTokens));
                        localStorage.setItem("clusterId", ciClusterId);
                        localStorage.setItem("ci-config", JSON.stringify(ci_config));
                        logger.info("UUIP token has been set for iframed desktop login");
                    }
                    STORE.auth.authService.getToken().then((updatedToken) => {
                        this.onCiLoginSuccess(updatedToken || token);
                        if (this.loginWindow) {
                            this.loginWindow.close();
                        }
                    });
                }
                else {
                    this.isrequestedIframeLogin = false;
                }
            }
        };
        // self invoked function to listen window post message
        this.listenIframeAuthMessage = () => {
            // Listener to get auth details for iframe login
            window.addEventListener("message", this.processIframeAuthMessage);
        };
        /**
         * End init routing
         */
        this.isEnvironmentProd = () => {
            var _a;
            return (_a = process.env.CC_DC) === null || _a === void 0 ? void 0 : _a.includes("prod");
        };
        this.logUnloadEvent = () => {
            logger.info("page reloaded for IFrame");
        };
        this.enableFocus = () => {
            STORE.browserNotifications.setIsTabFocus(true);
        };
        this.disableFocus = () => {
            STORE.browserNotifications.setIsTabFocus(false);
        };
        this.deregisterDevices = () => {
            unRegisterWebexSDK();
            if (STORE.app.featureFlags.isWebRTCEnabled &&
                STORE.agent.deviceType === SERVICE.constants.LoginVoiceOptions.BROWSER) {
                SERVICE.webCalling.deregisterWebCalling();
            }
        };
        this.getSpecificIdleCode = (auxCodeId) => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield SERVICE.abs.fetchIdleCodes({
                    orgId: STORE.agent.organizationId,
                    accessType: "SPECIFIC",
                    ids: [auxCodeId]
                });
                logger.info(`event=fetchIdleCodes | Successfully fetched idle code for subStatus`);
                return response;
            }
            catch (error) {
                logger.error("event=fetchIdleCodes | Error on fetching idle code for subStatus", error);
                throw error;
            }
        });
        this.loginWindow = undefined;
    }
    agentXRoutingStart() {
        this.agentXAppRouting.start({
            onRoutingUpdate: () => __awaiter(this, void 0, void 0, function* () { return this.requestUpdate(); })
        });
    }
    agentXRoutingStop() {
        this.agentXAppRouting.stop();
    }
    connectedCallback() {
        const _super = Object.create(null, {
            connectedCallback: { get: () => super.connectedCallback }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.connectedCallback.call(this);
            localStorage.removeItem("uuip-logs"); // removing the old variable for uuip logs from localStorage
            setQueryparams();
            const windowOpener = window.opener;
            this.isrequestedIframeLogout = isIframedLogoutRequested(windowOpener);
            logger.info("printing isrequestedIframeLogout flag:", this.isrequestedIframeLogout);
            if (!sessionStorage.getItem("isReload") && !this.isIframe && !this.isrequestedIframeLogout) {
                // remove uuip-auth-token only in case of new login, not for each reload. Not removing for iframe
                localStorage.removeItem("uuip-auth-token");
                logger.info("uuip-auth-token removed from localStorage");
            }
            const token = yield STORE.auth.authService.getToken();
            if (token) {
                logger.info("auth token is present");
                logger.info("event=UserAgent | Navigator User Agent", navigator === null || navigator === void 0 ? void 0 : navigator.userAgent);
                sessionStorage.setItem("isReload", "true");
                if (this.shouldReloadForNewVersion()) {
                    window.location.replace(window.location.href.indexOf("?") === -1
                        ? `${window.location.href}?canary=true`
                        : `${window.location.href}&canary=true`);
                }
                yield handleLogout(token.refresh_token);
                this.onCiLoginSuccess(token);
            }
            else if (!this.isIframe) {
                logger.info("login for desktop loaded in window");
                ScreenPop.removeScreenPopFromSession();
                STORE.auth.authService.login();
            }
            else {
                logger.info("login for desktop loaded inside IFrame");
                this.isrequestedIframeLogin = false;
            }
            // Initiate CI logout when event is received from agentx-js-api
            window.addEventListener("js-sdk-ci-logout", () => {
                STORE.auth.logout(STORE.auth.refreshToken);
            });
            if (!this.isIframe) {
                window.addEventListener("beforeunload", tabClosePrompt);
            }
            else {
                window.addEventListener("beforeunload", this.logUnloadEvent);
            }
            window.addEventListener("focus", this.enableFocus);
            window.addEventListener("blur", this.disableFocus);
            // Deregister device registered by webex-sdk and media-view.
            window.addEventListener("pagehide", this.deregisterDevices);
            window.addEventListener("agentx-settings-set", (e) => {
                if (e.detail.settingsSet && e.detail.value && e.detail.value.length > 0) {
                    STORE.app.updateWxmEnabled(e.detail.settingsSet);
                }
                else {
                    STORE.app.updateWxmEnabled(false);
                }
            });
            const connectorView = window.matchMedia("(max-width: 500px)");
            connectorView.addEventListener("change", this.updateResponsiveList.bind(this));
            if (window.innerWidth <= 500) {
                STORE.dynamic.updateScreenSize(true);
            }
            let resizeTimer;
            window.addEventListener("resize", () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.onResize();
                }, 250);
            });
            const unreadMsg = sessionStorage.getItem("unread-chat-message");
            STORE.session.chat.getUnreadMessagesFromSessionStorage(unreadMsg ? JSON.parse(unreadMsg) : null);
            window.addEventListener("web-calling-device-registered", (e) => {
                var _a, _b;
                this.isWebexCallingDeviceRegistered = e.detail;
                triggerWebRTCSuccessNotification();
                STORE.agent.updateWebRtcStatus(WEB_RTC_STATUS.WEB_RTC_CONNECTED);
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.WEBRTC_REGISTRATION, {
                    WebexCallingDeviceRegistered: e.detail.value
                }, [(_b = (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.SERVICE_PROVIDERS) === null || _b === void 0 ? void 0 : _b.mixpanel]);
            });
            window.addEventListener("web-calling-device-registering", () => {
                STORE.agent.updateWebRtcStatus(WEB_RTC_STATUS.WEB_RTC_REGISTERING);
            });
            window.addEventListener("web-calling-device-disconnected", (event) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.WEBRTC_REGISTRATION_FAILURE, [
                    (_b = (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.SERVICE_PROVIDERS) === null || _b === void 0 ? void 0 : _b.prometheus
                ]);
                triggerWebRTCErrorNotification();
                STORE.agent.updateWebRtcStatus(WEB_RTC_STATUS.WEB_RTC_DISCONNECTED);
                const errorDetails = (_d = (_c = event === null || event === void 0 ? void 0 : event.detail) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.errorDetails;
                const errorDetailsObj = errorDetails ? JSON.parse(errorDetails) : null;
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.WEBRTC_REGISTRATION_FAILURE, {
                    user_id: errorDetailsObj === null || errorDetailsObj === void 0 ? void 0 : errorDetailsObj.userId,
                    error_code: (errorDetailsObj === null || errorDetailsObj === void 0 ? void 0 : errorDetailsObj.errorCode) || (errorDetailsObj === null || errorDetailsObj === void 0 ? void 0 : errorDetailsObj.statusCode),
                    Reason: (errorDetailsObj === null || errorDetailsObj === void 0 ? void 0 : errorDetailsObj.message) || (errorDetailsObj === null || errorDetailsObj === void 0 ? void 0 : errorDetailsObj.name) || ((_e = errorDetailsObj === null || errorDetailsObj === void 0 ? void 0 : errorDetailsObj.body) === null || _e === void 0 ? void 0 : _e.message),
                    mode: (_g = (_f = event === null || event === void 0 ? void 0 : event.detail) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.mode
                }, [(_j = (_h = SERVICE.telemetry) === null || _h === void 0 ? void 0 : _h.SERVICE_PROVIDERS) === null || _j === void 0 ? void 0 : _j.mixpanel, (_l = (_k = SERVICE.telemetry) === null || _k === void 0 ? void 0 : _k.SERVICE_PROVIDERS) === null || _l === void 0 ? void 0 : _l.prometheus]);
            });
            window.addEventListener(TOGGLE_VOICE_INTERACTION_PANEL, toggleVoiceInteractionPanel);
            window.addEventListener(AGENT_STATE_CHANGE_FAILED, triggerAgentStateErrorNotification);
            window.addEventListener(AGENT_SIGNOUT_RESULT, triggerAgentSignoutResultNotification);
            window.addEventListener("outdial-update-ani-list-jssdk", updateOutdialAniList);
            presenceRequestListeners(); // add the event listeners for mercury device and connect status
            // if child window is opened for iframe authentication case
            if (window.opener) {
                findAuthTokenForIframeApp();
            }
            else {
                this.listenIframeAuthMessage();
            }
        });
    }
    updateResponsiveList(e) {
        if (e.matches) {
            STORE.dynamic.updateScreenSize(true);
        }
        else {
            STORE.dynamic.updateScreenSize(false);
        }
        STORE.dynamic.updateLayoutForConnectorViewUnresponsiveWidget(this.agentXAppRouting);
    }
    getSubStatus(auxCodeId) {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function* () {
            let subStatus = "Available";
            if (auxCodeId !== "0") {
                if (STORE.app.featureFlags.isDesktopAuxCodesInfiniteScrollEnabled) {
                    const idleCodeStatus = (_d = (_c = (_b = (_a = STORE.agent.getAgentStateProps.agentStateIdleCodes) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.find((c) => c.id.toString() === auxCodeId)) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : "";
                    if (!idleCodeStatus) {
                        const response = yield this.getSpecificIdleCode(auxCodeId);
                        if (response && (response === null || response === void 0 ? void 0 : response.length) > 0) {
                            subStatus = response[0].name;
                        }
                    }
                    else {
                        subStatus = idleCodeStatus;
                    }
                }
                else {
                    subStatus = (_f = (_e = STORE.agent.idleCodes.find((c) => c.id.toString() === auxCodeId)) === null || _e === void 0 ? void 0 : _e.name) !== null && _f !== void 0 ? _f : "";
                }
                return subStatus;
            }
            return subStatus;
        });
    }
    onCiLoginSuccess(token) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            logger.info(`event=onCiLoginSuccess | CI Login Success | token expiry timestamp : ${token.expires_in}`);
            this.storeAuthToken(token);
            const meData = yield this.fetchAndVerifyMeDetails();
            if (meData) {
                yield this.setupSplitIOClient((_a = meData.meta) === null || _a === void 0 ? void 0 : _a.organizationID);
                this.storeAgentDetails(meData);
                yield this.initDependenciesAndFinishSetup(token, meData);
            }
        });
    }
    storeAuthToken(token) {
        STORE.auth.tokenSet(token.access_token);
        STORE.auth.refreshTokenSet(token.refresh_token);
        STORE.auth.authService.tokenChange.listen((data) => {
            logger.info("Updated Access Token received");
            updateAccessToken(data.token.access_token);
            STORE.auth.tokenSet(data.token.access_token);
            STORE.auth.refreshTokenSet(data.token.refresh_token);
        });
        STORE.auth.authService.onRefreshingTokenFail.listen(() => {
            var _a, _b, _c, _d;
            logger.info("Refreshing of access token failed");
            const telemetryData = {
                Status: "Failed",
                Reason: "Token Refresh Failed"
            };
            SERVICE.telemetry.timeEvent(SERVICE.telemetry.MIX_EVENT.CI_TOKEN_REFRESH, [
                (_b = (_a = SERVICE.telemetry) === null || _a === void 0 ? void 0 : _a.SERVICE_PROVIDERS) === null || _b === void 0 ? void 0 : _b.prometheus
            ]);
            SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.CI_TOKEN_REFRESH, telemetryData, [
                (_d = (_c = SERVICE.telemetry) === null || _c === void 0 ? void 0 : _c.SERVICE_PROVIDERS) === null || _d === void 0 ? void 0 : _d.prometheus
            ]);
            localStorage.removeItem("uuip-auth-token");
            this.isSessionExpired = true;
        });
    }
    fetchAndVerifyMeDetails() {
        return __awaiter(this, void 0, void 0, function* () {
            const ci = new CI.Service({
                accessToken: STORE.auth.accessToken,
                identityUrl: STORE.auth.authService.config.identityUrl
            });
            const meData = yield fetchMeDetails(ci);
            STORE.app.setIsEnvironmentProd(this.isEnvironmentProd() || false);
            STORE.app.setDomainUrl(process.env.RTMS_DOMAIN || "");
            STORE.app.setDatacenter(process.env.CC_DC || "");
            if (!meData) {
                this.isInitialized = true;
                logger.error("[meData]: invalid meData ");
                invokeLoginErrorModal(t("app:stationLogin.initialConfigsFailed"));
                return null;
            }
            if (!meData.licenseID || meData.licenseID.length === 0) {
                this.isInitialized = true;
                logger.error("[meData]: invalid licenseID");
                invokeLoginErrorModal(t("app:stationLogin.agentLicenseError"));
                return null;
            }
            if (!meData.roles || meData.roles.length === 0) {
                this.isInitialized = true;
                logger.error("[meData]: invalid roles");
                invokeLoginErrorModal(t("app:stationLogin.initialProfileRoleFailed"));
                return null;
            }
            STORE.agent.updateAgentId(meData === null || meData === void 0 ? void 0 : meData.id);
            return meData;
        });
    }
    setupSplitIOClient(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const splitIOClient = yield SERVICE.featureflag.initSplitioSdk(organizationId, process.env.SPLIT_KEY_TOKEN);
                splitIOClient.on(splitIOClient.Event.SDK_UPDATE, () => __awaiter(this, void 0, void 0, function* () {
                    logger.info("Feature flags updated by admin");
                    yield updateFeatureFlags();
                }));
            }
            catch (_a) {
                logger.error("Error in initialising Split IO SDK");
                STORE.alertBanner.updateAlertBanner({
                    message: t("app:common.alertBannerDefaultMessage"),
                    display: true,
                    type: "warning",
                    closable: false
                });
            }
        });
    }
    storeAgentDetails(meData) {
        const queryOrgId = getOrgIdParam();
        const orgsAvailable = meData.managedOrgs && meData.managedOrgs.length > 0;
        if (queryOrgId && orgsAvailable) {
            const orgDetails = meData.managedOrgs ? getManagedOrgDetails(meData.managedOrgs, queryOrgId) : null;
            if (orgDetails) {
                const systemRole = getUserSystemRole(orgDetails);
                const userRoles = meData === null || meData === void 0 ? void 0 : meData.roles;
                if (systemRole ||
                    (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes(USER_ROLES.PREMIUM_AGENT)) ||
                    (userRoles === null || userRoles === void 0 ? void 0 : userRoles.includes(USER_ROLES.STANDARD_AGENT))) {
                    STORE.agent.setRole(SYSTEM_ROLES.AGENT, meData, orgDetails);
                }
                else if (userRoles.includes(USER_ROLES.SUPERVISOR)) {
                    STORE.agent.setRole(SYSTEM_ROLES.SUPERVISOR, meData, orgDetails);
                }
                else {
                    logger.error("event=initAgentxServices | Error in fetching user system role", {
                        queryOrgId,
                        meData: JSON.stringify(meData)
                    });
                    invokeLoginErrorModal(t("app:stationLogin.initialConfigsFailed"));
                    return;
                }
            }
        }
        else {
            STORE.agent.updateAgentMe(meData);
        }
        localStorage.setItem("userRole", STORE.agent.getUserRole);
    }
    initDependenciesAndFinishSetup(token, meData) {
        return __awaiter(this, void 0, void 0, function* () {
            // change this line according to role based admin access story
            if (meData.roles.includes(USER_ROLES.SUPERVISOR)) {
                this.loadRTDWC();
                updateAnalyzerSettings();
            }
            if (multipleExist(meData === null || meData === void 0 ? void 0 : meData.roles, [USER_ROLES["SUPERVISOR"]])) {
                !STORE.app.taskAreaCollapsed && STORE.app.toggleTaskAreaCollapse();
            }
            sessionStorage.setItem("orgId", STORE.agent.organizationId);
            // log rocket integration
            if (SERVICE.featureflag.isLogRocketEnabled()) {
                let enableSanitization = true;
                if (SERVICE.featureflag.isLogRocketMaskingDisabled()) {
                    enableSanitization = false;
                }
                SERVICE.logrocket.init(process.env.LOGROCKET_AUTHORIZATION_KEY, enableSanitization);
                SERVICE.logrocket.identify(meData.id, {
                    orgId: meData.meta.organizationID,
                    DC: process.env.CC_DC
                });
            }
            SERVICE.telemetry.init(process.env.MIX_PANEL_PROJECT_TOKEN);
            yield initAgentxMfeWcBased({
                ANALYZER_HOST: process.env.ANALYZER_HOST,
                WXM_GADGET_URL: process.env.WXM_GADGET_URL,
                WEBEX_APP_URL: process.env.WEBEX_APP_URL,
                WEBEX_SETTING_SERVICE: process.env.WEBEX_SETTING_SERVICE,
                AG_GRID_LICENSE_KEY: process.env.AG_GRID_LICENSE_KEY
            });
            try {
                yield initAgentxServices({
                    AQM_HOST: process.env.AQM_HOST,
                    U2C_SERVICE_URL: process.env.U2C_SERVICE_URL,
                    COMPLIANCE_SERVICE_URL: process.env.COMPLIANCE_SERVICE_URL,
                    CONFIG_URL: process.env.CONFIG_URL,
                    EMM_HOST: process.env.EMM_HOST,
                    NOTIF_HOST: process.env.API_GATEWAY,
                    DIALER_HOST: process.env.DIALER_HOST,
                    MEDIA_SERVICE_HOST: process.env.MEDIA_SERVICE_HOST,
                    MIX_PANEL_PROJECT_TOKEN: process.env.MIX_PANEL_PROJECT_TOKEN,
                    API_GATEWAY: process.env.API_GATEWAY,
                    AGENTX_BACKEND_PROXY_SERVICE_HOST: process.env.AGENTX_BACKEND_PROXY_SERVICE_HOST,
                    WELCOME_TIMEOUT: process.env.WELCOME_TIMEOUT,
                    SPLIT_KEY_TOKEN: process.env.SPLIT_KEY_TOKEN,
                    LOGROCKET_AUTHORIZATION_KEY: process.env.LOGROCKET_AUTHORIZATION_KEY,
                    HOST_URL: process.env.HOST_URL,
                    CC_DC: process.env.CC_DC,
                    JABBER_HOST_URL: process.env.JABBER_HOST_URL,
                    MICROSOFT_CLIENT_ID: process.env.MICROSOFT_CLIENT_ID
                }, token.access_token, STORE.auth.authService);
                this.isNotifsSuccess = true;
                logNtpTime();
            }
            catch (error) {
                if (error.message && (error.message.includes("403") || error.message.includes("401"))) {
                    logger.error("event=initAgentxServices | Error in Agent License", error);
                    invokeLoginErrorModal(t("app:stationLogin.agentLicenseError"));
                }
                else {
                    logger.error("event=initAgentxServices | Error in notifs registration", error);
                    invokeLoginErrorModal(t("app:stationLogin.socketRegisterFailed"));
                }
            }
            yield updateFeatureFlags();
            STORE.app.updateTheme(this.getTheme());
            const urlSearchParams = new URLSearchParams(window.location.search);
            const allParams = {};
            for (const [key, val] of urlSearchParams.entries()) {
                allParams[key] = val;
            }
            allParams && STORE.routing.syncQueryParams(allParams);
            if (STORE.app.featureFlags.isImiChatWidgetEnabled) {
                updateIMISettings();
            }
            STORE.app.updateWebexEnabled(false);
            SERVICE.aqm.connectionConfig.OnConnectionLost.listen(this.handleConnectionLost.bind(this));
            SERVICE.aqm.agent.eAgentMultiLogin.listen(() => {
                if (STORE.auth.accessToken) {
                    removeUnloadEvent();
                    this.isMultiLogin = true;
                    logger.info("triggering webex deregistration for multi login scenario !");
                    unRegisterWebexSDK();
                    this.removeIdleActivity();
                }
            });
            SERVICE.aqm.agent.eAgentLogoutSuccess.listen((event) => {
                checkAgentLogout(event.data);
            });
            initSharedStoreActions();
            SERVICE.aqm.agent.eAgentStationLoginSuccess.listen((data) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c, _d;
                // Return if change team is in process, as we don't need to attach all listeners again
                if (STORE.app.changeProfileSettingsLoading) {
                    // setTimeout is used here, because when the code is getting executed the latest userSelectedRole won't be updated in the store;
                    setTimeout(() => {
                        this.updateLayout(data.data.teamId);
                    });
                    return;
                }
                if (!STORE.app.changeTeamLoading) {
                    return;
                }
                const eventELoginSuccess = {
                    detail: {
                        dn: localStorage.getItem("agent-dn-number") || STORE.agent.dnNumber,
                        agentId: data.data.agentId,
                        usesOtherDN: STORE.agent.usesOtherDN,
                        isExtension: STORE.agent.isExtension,
                        defaultDn: STORE.agent.defaultDn,
                        isDefaultDnSet: STORE.agent.isDefaultDnSet,
                        teamId: data.data.teamId,
                        teamName: STORE.agent.teamName,
                        subStatus: yield this.getSubStatus(data.data.auxCodeId),
                        lastIdleCodeChangeTimestamp: data.data.lastIdleCodeChangeTimestamp,
                        lastStateChangeTimestamp: data.data.lastStateChangeTimestamp,
                        profileType: data.data.profileType,
                        mmProfile: {
                            voiceCount: (_a = data.data.channelsMap["telephony"]) === null || _a === void 0 ? void 0 : _a.length,
                            chatCount: (_b = data.data.channelsMap["chat"]) === null || _b === void 0 ? void 0 : _b.length,
                            emailCount: (_c = data.data.channelsMap["email"]) === null || _c === void 0 ? void 0 : _c.length,
                            socialCount: (_d = data.data.channelsMap["social"]) === null || _d === void 0 ? void 0 : _d.length
                        },
                        interactionIds: data.data.interactionIds,
                        userRole: STORE.agent.userRole,
                        selectedRoleName: STORE.agent.userSelectedRole,
                        deviceType: STORE.agent.deviceType
                    }
                };
                if (STORE.app.changeTeamLoading) {
                    this.cachedLayoutConfig = getLayoutConfigFromCache();
                }
                this.stationLoginSuccess(eventELoginSuccess);
            }));
            this.isrequestedIframeLogin = false;
            this.isInitialized = true;
            this.agentXRoutingStart();
            this.cachedLayoutConfig = getLayoutConfigFromCache();
            if (this.cachedLayoutConfig) {
                yield processCachedLayoutJson(this.cachedLayoutConfig, this.agentXAppRouting);
            }
            this.updateWxmSettings(token);
            /** Handle screen pop event */
            listenScreenPopEvent();
        });
    }
    getTheme() {
        return STORE.app.featureFlags.isMomentumV2Enabled ? MOMENTUM_V2 : LUMOS;
    }
    reloginAqm(isConnectionLost) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                logger.info("event=requestSilentRelogin | Requesting relogin on socket reconnect");
                const profile = SERVICE.conf.profile ? SERVICE.conf.profile : yield SERVICE.conf.fetchProfile();
                const reload = yield SERVICE.aqm.agent.reload();
                const { dn, agentId, teamId, lastStateChangeReason, profileType, channelsMap, interactionIds } = reload.data;
                let { auxCodeId, lastStateChangeTimestamp, lastIdleCodeChangeTimestamp } = reload.data;
                if (lastStateChangeReason === "agent-wss-disconnect") {
                    logger.info("event=requestAutoStateChange | Requesting state change to available on socket reconnect");
                    const stateChangeData = yield SERVICE.aqm.agent.stateChange({
                        data: {
                            state: SERVICE.constants.AgentState.Available,
                            auxCodeIdArray: SERVICE.constants.AgentState.AvailableAuxCodeId
                        }
                    });
                    logger.info("event=autoStateChangeSuccess | State change after socket reconnect success", stateChangeData);
                    auxCodeId = SERVICE.constants.AgentState.AvailableAuxCodeId;
                    lastStateChangeTimestamp = stateChangeData.data.lastStateChangeTimestamp;
                    lastIdleCodeChangeTimestamp = stateChangeData.data.lastIdleCodeChangeTimestamp;
                }
                if (isMSStateSyncEnabled()) {
                    StateSyncManager.getCurrentPresenceStatus();
                }
                const subStatus = yield this.getSubStatus(auxCodeId);
                const teamName = (_a = profile.teams.find((t) => t.teamId === teamId)) === null || _a === void 0 ? void 0 : _a.teamName;
                if (!teamName && STORE.agent.role !== UserRoles.SUPERVISOR) {
                    invokeTeamDeletedErrorModal(t("app:stationLogin.teamDeletedError"));
                    return;
                }
                const loginData = {
                    dn,
                    agentId,
                    usesOtherDN: true,
                    teamId,
                    teamName,
                    subStatus,
                    lastStateChangeTimestamp,
                    lastIdleCodeChangeTimestamp,
                    lastStateChangeReason: lastStateChangeReason ? lastStateChangeReason : "",
                    profileType,
                    interactionIds,
                    mmProfile: {
                        voiceCount: (_b = channelsMap["telephony"]) === null || _b === void 0 ? void 0 : _b.length,
                        chatCount: (_c = channelsMap["chat"]) === null || _c === void 0 ? void 0 : _c.length,
                        emailCount: (_d = channelsMap["email"]) === null || _d === void 0 ? void 0 : _d.length,
                        socialCount: (_e = channelsMap["social"]) === null || _e === void 0 ? void 0 : _e.length
                    }
                };
                STORE.agent.updateInitialConfiguration(profile);
                STORE.agent.updateAgentProfileLogin(loginData);
                STORE.agentContact.setInteractionIds(loginData.interactionIds);
                STORE.agentContact.removeAutoCompleteTasks();
                STORE.app.setChangeTeamsLoginFailed(false);
                STORE.app.setChangeProfileSettingsLoginFailed(false);
                STORE.session.chat.setRetryMediaViewInit();
                STORE.agent.updateLastStateChangeByPresence(lastStateChangeReason === PRESENCE_SYNC);
                this.isConnectionLost = isConnectionLost;
            }
            catch (error) {
                logger.error(`event=reloginFailedAfterRecovery | [Relogin] Agent Relogin failed after connection restore  ${JSON.stringify(error)}`, error);
                SERVICE.aqm.closeSocket(false, "reloginFailedAfterRecovery");
                this.isRestoreFailed = true;
                this.isConnectionLost = true;
            }
        });
    }
    handleConnectionLost(detail) {
        var _a;
        this.isRestoreFailed = detail.isRestoreFailed ? detail.isRestoreFailed : false;
        if (detail.isSocketReconnected) {
            // Mix Panel tracking for connection  loss scenario
            if (SERVICE.telemetry.getValueFromCache(SERVICE.telemetry.MIX_CACHE_KEYS.CONNECTION_LOSS_START_TIME) > 0) {
                const connectionLossDuration = SERVICE.telemetry.getValueFromCache(SERVICE.telemetry.MIX_CACHE_KEYS.CONNECTION_LOSS_START_TIME);
                SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.DESKTOP_CONNECTION_LOSS, {
                    [SERVICE.telemetry.MIX_PROPS.TOTAL_CONNECTION_LOST_DURATION]: Number(((new Date().getTime() - Number(connectionLossDuration)) / 1000).toFixed(2))
                });
                SERVICE.telemetry.removeValueFromCache(SERVICE.telemetry.MIX_CACHE_KEYS.CONNECTION_LOSS_START_TIME);
            }
            // socket is reconnected in chrome and FF
            if (this.isLoggedIn) {
                //invoke reload post connectionrecovery only if stationLogin was done
                this.reloginAqm(detail.isConnectionLost);
            }
            else {
                this.isConnectionLost = detail.isConnectionLost;
            }
            if ((_a = this.agentXAppRouting) === null || _a === void 0 ? void 0 : _a.STORE.app.webexEnabled) {
                SERVICE === null || SERVICE === void 0 ? void 0 : SERVICE.webex.fetchUnreadConversationCount();
            }
        }
        else {
            // keepalive stopped and socket closed in FF and open in chrome
            this.isConnectionLost = detail.isConnectionLost;
            if (!SERVICE.telemetry.getValueFromCache(SERVICE.telemetry.MIX_CACHE_KEYS.CONNECTION_LOSS_START_TIME)) {
                SERVICE.telemetry.setValueInCache(SERVICE.telemetry.MIX_CACHE_KEYS.CONNECTION_LOSS_START_TIME, new Date().getTime());
            }
        }
        if (!detail.isKeepAlive && (detail.isConnectionLost || detail.isRestoreFailed)) {
            logger.error("event=handleConnectionLost | Connection status changed: ", detail);
        }
        else if (detail.isSocketReconnected) {
            logger.info("event=handleConnectionLost | Connection status changed: ", detail);
        }
    }
    requestIframeLogin() {
        this.isrequestedIframeLogin = true;
        this.loginWindow = window.open(window.location.href);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.agentXRoutingStop();
        removeMercuryListeners(); // remove the event listeners for mercury device and connect status
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("rtd-subs-details", this.rtdcSubscriptionLog);
        window.removeEventListener("focus", this.enableFocus);
        window.removeEventListener("blur", this.disableFocus);
        this.removeIdleActivity();
        STORE.interactionControl.clearTimers();
        window.removeEventListener(TOGGLE_VOICE_INTERACTION_PANEL, toggleVoiceInteractionPanel);
        window.removeEventListener(AGENT_STATE_CHANGE_FAILED, triggerAgentStateErrorNotification);
        window.removeEventListener(AGENT_SIGNOUT_RESULT, triggerAgentSignoutResultNotification);
        window.removeEventListener("pagehide", this.deregisterDevices);
        window.removeEventListener("outdial-update-ani-list-jssdk", updateOutdialAniList);
    }
    static get styles() {
        return style;
    }
    dispatchTelemetryEvent(e) {
        var _a, _b, _c, _d, _e, _f, _g;
        const telemetryData = {
            UserRole: getRoleNameForTelemetry(e.detail.selectedRoleName || ((_a = e.detail.userRole) === null || _a === void 0 ? void 0 : _a.join("_")[0])),
            LoginType: e.detail.loginType,
            DeviceType: e.detail.deviceType,
            Status: SERVICE.telemetry.MIX_EVENT.STATUS.SUCCESS,
            AdvancedHeader: e.detail.advancedHeader
        };
        if (isMSStateSyncEnabled()) {
            telemetryData.CollaborationTool = COLLAB_TOOL.TEAMS;
        }
        else if (isWebexStateSyncEnabled()) {
            telemetryData.CollaborationTool = COLLAB_TOOL.WEBEX;
        }
        telemetryData.PresenceSyncEnabled = !!telemetryData.PresenceSyncEnabled;
        SERVICE.telemetry.track(SERVICE.telemetry.MIX_EVENT.STATION_LOGIN, telemetryData, [
            (_c = (_b = SERVICE.telemetry) === null || _b === void 0 ? void 0 : _b.SERVICE_PROVIDERS) === null || _c === void 0 ? void 0 : _c.mixpanel,
            (_e = (_d = SERVICE.telemetry) === null || _d === void 0 ? void 0 : _d.SERVICE_PROVIDERS) === null || _e === void 0 ? void 0 : _e.prometheus,
            (_g = (_f = SERVICE.telemetry) === null || _f === void 0 ? void 0 : _f.SERVICE_PROVIDERS) === null || _g === void 0 ? void 0 : _g.webex_behavioral
        ]);
    }
    handleWebexStateSync(webexConfig) {
        const enabledFFs = STORE.app.featureFlags.isDesktopWebexStateSyncEnabled || STORE.app.featureFlags.isWebexDirectoryViewEnabled;
        if (!isOnlySupervisor() && enabledFFs) {
            fetchAndHandleWebexConfig({
                detail: {
                    orgId: STORE.agent.organizationId,
                    webexConfig
                }
            });
        }
        else {
            logger.info("[Webex]: fetchAndHandleWebexConfig is not called");
        }
    }
    stationLoginSuccess(e) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            logger.info("event=stationLoginSuccess | Station login success", e.detail);
            if (!SERVICE.conf.profile) {
                logger.error("event=NoConfigProfile | No Config profile after StationLogin");
                return;
            }
            sessionStorage.setItem("agentId", e.detail.agentId);
            STORE.agent.updateUserRole(e.detail.userRole);
            STORE.agent.updateSelectedAgentRole(e.detail.selectedRoleName);
            STORE.agent.updateInitialConfiguration(SERVICE.conf.profile);
            STORE.agent.updateIsExtension(e.detail.isExtension || false);
            if (STORE.app.featureFlags.isWebRTCEnabled) {
                STORE.agent.updateDeviceType(e.detail.deviceType || null);
            }
            if (STORE.app.featureFlags.isAiAssistantEnabled && !this.messageHandler) {
                logger.info("Initialising MessageHandler for AI Assistant");
                this.messageHandler = new MessageHandler({ aiAssistantMessageProcessor: new AiAssistantMessageProcessor() });
            }
            dispatchUpdatedAgentProfileEvent(this);
            if (!isOnlySupervisor()) {
                STORE.agent.updateAgentProfileLogin(e.detail);
            }
            localStorage.setItem("userRole", STORE.agent.getUserRole);
            if (((_a = e.detail) === null || _a === void 0 ? void 0 : _a.lastStateChangeReason) === "agent-wss-disconnect") {
                logger.info("event=requestAutoStateChange | Requesting state change to available on reload");
                SERVICE.aqm.agent
                    .stateChange({
                    data: {
                        state: SERVICE.constants.AgentState.Available,
                        auxCodeIdArray: SERVICE.constants.AgentState.AvailableAuxCodeId
                    }
                })
                    .then((data) => {
                    logger.info("event=autoStateChangeSuccess | State change after reload success", data);
                    STORE.agent.updateSubStatus(SERVICE.constants.AgentState.Available, data.data.lastStateChangeTimestamp, data.data.lastIdleCodeChangeTimestamp);
                });
            }
            STORE.agent.updateLastStateChangeByPresence(((_b = e.detail) === null || _b === void 0 ? void 0 : _b.lastStateChangeReason) === PRESENCE_SYNC);
            //update APS based on config
            if (SERVICE.conf.profile.agentPersonalStatsEnabled) {
                STORE.layout.updateApsDisplay();
            }
            // Webex registration
            if (this.isWebexRegistrationRequired()) {
                yield this.handleWebexRegister();
            }
            registerDefaultShortcutKeys();
            if (STORE.app.featureFlags.isWebRTCEnabled && e.detail.deviceType === "BROWSER") {
                registerWebRTCShortcuts();
                registerWebCalling(STORE.app.domainUrl);
            }
            let teamId;
            if (STORE.agent.getUserRole === UserRoles.SUPERVISOR) {
                teamId = SERVICE.conf.profile.preferredSupervisorTeamId;
            }
            yield processJSONLayout(SERVICE.conf.profile, teamId ? teamId : e.detail.teamId || "", this.cachedLayoutConfig, this.agentXAppRouting);
            // On reload if screen pop is for "insideDesktop" and already exists show screen pop
            STORE.session.screenpop.updateScreenPopStoreOnReload();
            this.isLoggedIn = true;
            STORE.app.updateStationLoginSuccess(true);
            //TODO: to be removed as its a workaround for props not rendering in interaction control in wrap up on reload
            setTimeout(() => {
                STORE.agentContact.rerenderTaskMap();
            });
            this.loadAcqueonScript();
            // Checking app pwa desktop install
            yield STORE.app.checkPwaInstall();
            yield updateOrgConfigs(STORE.agent.organizationId);
            if (STORE.agent.getUserRole !== UserRoles.AGENT) {
                // Fetch managed teams and managed queues on station login. To retain the placeholder values on load/reload.
                fetchManagedTeams();
                fetchManagedQueues();
            }
            // initialize the webex state registration
            this.handleWebexStateSync(SERVICE.conf.profile.webexConfig);
            // On station login success dispatch event to Mixpanel, not on reload
            if (!e.detail.isReload) {
                this.dispatchTelemetryEvent(e);
            }
        });
    }
    isWebexRegistrationRequired() {
        return !STORE.app.webexEventRegistered;
    }
    handleWebexRegister() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield SERVICE.webex.registerWebex(STORE.app.isEnvironmentProd);
                if (STORE.app.webexEnabled) {
                    SERVICE.webex.registerWebexEvents();
                    subscribeToWebexEvents();
                    SERVICE.webex.updateWebexMeeting();
                    STORE.app.updateWebexEventRegistrationStatus(true);
                }
            }
            catch (error) {
                logger.error("event=webexEventRegistrationFailed | Error in registering webex events", error);
                STORE.app.updateWebexEventRegistrationStatus(false);
            }
        });
    }
    updateLayout(teamId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!SERVICE.conf.profile) {
                logger.error("event=NoConfigProfile | No Config profile after StationLogin");
                return;
            }
            this.cachedLayoutConfig = getLayoutConfigFromCache();
            let supTeamId;
            if (STORE.agent.getUserRole === UserRoles.SUPERVISOR) {
                supTeamId = SERVICE.conf.profile.preferredSupervisorTeamId;
            }
            yield processJSONLayout(SERVICE.conf.profile, supTeamId ? supTeamId : teamId, this.cachedLayoutConfig, this.agentXAppRouting);
        });
    }
    stationLoginError(e) {
        logger.error("event=stationLoginError | ", e.detail);
        handleLoginError(e.detail);
    }
    noVoiceOptionsError(e) {
        this.isNoVoiceOptions = true;
        STORE.session.errorModal.setErrorDetails(e);
    }
    onStationLoginSuccess(e) {
        // Set Initial Set of 50 Idle Codes to Store if InfiniteScroll FF is Enabled.
        if (STORE.app.featureFlags.isDesktopAuxCodesInfiniteScrollEnabled) {
            STORE.agent.updateAgentStateIdleCodes(e.detail.initialIdleCodes);
        }
        this.stationLoginSuccess(e);
        if (STORE.agent.isTimeoutDesktopInactivityEnabled) {
            this.initializeIdleActivity();
        }
        if (e.detail.interactionIds) {
            STORE.agentContact.setInteractionIds(e.detail.interactionIds);
        }
        STORE.app.setChangeTeamsLoginFailed(false);
        STORE.app.setChangeProfileSettingsLoginFailed(false);
    }
    onLogoutConfirm(e) {
        STORE.session.errorModal.setErrorDetails(e);
    }
    errorModalDisplay(e) {
        logger.info("dispatched modal event", e);
        STORE.session.errorModal.updateModalStatus(e.detail);
    }
    createAnalyzerSession() {
        analyzerSessionHelper.create();
        return nothing;
    }
    loadAcqueonScript() {
        if (STORE.agent.isCampaignManagementEnabled) {
            const script = document.createElement("script");
            script.setAttribute("type", "module");
            script.setAttribute("id", "acqueon-bundle");
            script.setAttribute("src", "acqueon/index.js");
            document.body.appendChild(script);
            script.onerror = () => {
                logger.error(`[App: Acqueon] failed to load Acqueon JS with src`, script.src);
            };
        }
    }
    loadRTDWC() {
        const script = document.createElement("script");
        script.setAttribute("id", "rtdwc");
        script.setAttribute("src", process.env.RTDWC_URL);
        document.body.appendChild(script);
        script.onerror = () => {
            logger.error(`[App: RTDWC] failed to load rtdwc`, script.src);
        };
        script.onload = () => {
            logger.info(`[App: RTDWC] successfully loaded rtdwc`, script.src);
            this.initializeRTDWC();
        };
    }
    rtdcSubscriptionLog(event) {
        logger.info("[App: RTDWC] web socket connection subscription details", event.detail);
    }
    initializeRTDWC() {
        var _a;
        const optionalProperties = { pingEvent: SERVICE.constants.RtdConstants.RTD_PING_EVENT };
        (_a = window.wxcc) === null || _a === void 0 ? void 0 : _a.rtdwc.init(Object.assign({
            accessToken: STORE.auth.accessToken,
            notifHost: process.env.NOTIF_HOST,
            notifUrl: process.env.NOTIF_DATA_URL,
            rtdUrl: process.env.RTD_URL,
            rtdHost: `${process.env.API_GATEWAY}/v1`,
            clientType: "Desktop",
            lostConnectionRecoveryTimeout: STORE.agent.lostConnectionRecoveryTimeout.toString(),
            httpHeaders: {
                "X-ORGANIZATION-ID": STORE.agent.organizationId
            }
        }, optionalProperties)).then((res) => {
            logger.info("[App: RTDWC] web socket connection was initialized successfully", res);
            STORE.app.updateIsRTDInitializedFlag(true);
        }).catch((err) => {
            logger.error("[App: RTDWC] web socket connection initialization failed with error", err);
            STORE.app.updateIsRTDInitializedFlag(false);
        });
        window.addEventListener("rtd-subs-details", this.rtdcSubscriptionLog);
    }
    updateWxmSettings(token) {
        STORE.app.updateUserModel(token.access_token, token.expires_in);
        const script = document.createElement("script");
        script.setAttribute("type", "module");
        script.setAttribute("id", "wxm-bundle");
        script.setAttribute("src", process.env.WXM_GADGET_URL);
        document.body.appendChild(script);
        script.onerror = () => {
            logger.error(`[App: WxM] failed to load WxM JS with src`, script.src);
        };
    }
    initializeIdleActivity() {
        if (!this.idleActivity && STORE.agent.timeoutDesktopInactivityMins) {
            logger.info("The Inactivity Timer has been initialized");
            this.idleActivity = new IdleActivity(STORE.agent.timeoutDesktopInactivityMins * 60000); //To pass minute value in ms
            this.idleActivity.handleInActivityTimer();
            this.idleActivity.register();
        }
    }
    removeIdleActivity() {
        if (this.idleActivity) {
            logger.info("The Inactivity Timer has been removed");
            this.idleActivity.deRegister();
        }
    }
    render() {
        return this.isInitialized && !STORE.auth.iframeLogoutRequested
            ? html `
          <img style="display: none" alt=${t("app:common.webex")} src=${wxmLogo} />
          <!-- APP HOST -->
          <md-theme theme=${STORE.app.theme} ?darkTheme="${STORE.app.darkMode}">
            <div class="app">
              <!-- CSS DYNAMIC -->
              <css-dynamic .vars="${STORE.app.cssDynamicVars}"></css-dynamic>

              <!-- ROUTING VIEW -->
              <router-view .view="${this.agentXAppRouting.view}"></router-view>
              ${!STORE.layout.isDesktopLayoutLoaded
                ? html ` <agentx-wc-loading-screen></agentx-wc-loading-screen> `
                : nothing}

              <!-- GENERAL NOTIFICATIONS HOST-->
              <uuip-wc-notifications-host
                class="general-notifications-host"
                .notificationsService="${STORE.generalNotifications.notifications}"
                .toasterHostPosition="${`top-right`}"
                .localization="${{ ignoreButtonText: t("app:notifications:ignoreNotificationsBtn") }}"
              ></uuip-wc-notifications-host>

              <!-- GENERAL MAXIMIZATION AREA HOST -->
              <uuip-wc-maximize-area-host
                .areaName="${STORE.app.defaultMaximizeAreaName}"
                .resizeAnimate="${true}"
                @maximize-transition-end=${() => {
                if (STORE.agentContact.isMediaTypeTelePhony) {
                    STORE.agentContact.toggleInteractionControlCADSection(true);
                }
            }}
                @minimize-transition-end=${() => {
                if (STORE.agentContact.isMediaTypeTelePhony) {
                    STORE.agentContact.toggleInteractionControlCADSection(false);
                }
            }}
                class="${classMap({
                "default-maximize-area": true,
                "task-area-expanded": !STORE.app.taskAreaCollapsed,
                "interaction-control-task-selected": !!STORE.agentContact.taskSelected && STORE.agentContact.isMediaTypeTelePhony
            })}"
              ></uuip-wc-maximize-area-host>

              <!-- ERROR MODAl -->
              <uuip-wc-error-modal
                .isModalOpen="${STORE.session.errorModal.isModalOpen}"
                .detail="${STORE.session.errorModal.errorDetails}"
                .localization="${STORE.session.errorModal.localization}"
                @errorModal-show=${this.errorModalDisplay}
              ></uuip-wc-error-modal>

              <!-- STATION LOGIN -->
              ${this.isLoggedIn && !STORE.app.changeTeamsError && !STORE.app.changeProfileSettingsError
                ? this.createAnalyzerSession()
                : this.isNotifsSuccess && !this.isMultiLogin && !this.isNoVoiceOptions
                    ? html `
                      <agentx-wc-user-station-login
                        ?isModalOpen="${STORE.app.isStationLoginOpen}"
                        ?showEmergencyNotificationWebrtc="${STORE.app.isEmergencyModalOpen}"
                        ?isAdvancedHeader="${!!STORE.dynamic.advancedHeader}"
                        ?isDesktopReasonCodeEnabled="${STORE.app.featureFlags.isDesktopReasonCodeEnabled}"
                        .getErrorMsgForReasonCode=${getErrorMsgForReasonCode}
                        ?intDiallingEnabledFF=${STORE.app.featureFlags.isInternationalDiallingEnabled}
                        ?isDesktopMaxSessionLimitsEnabled="${STORE.app.featureFlags.isDesktopMaxSessionLimitsEnabled}"
                        roleFromMe="${STORE.agent.role}"
                        ?isMonitoringEnabled="${STORE.agent.isMonitoringEnabled}"
                        selectedRoleName="${STORE.agent.userSelectedRole}"
                        isDarkMode="${STORE.app.darkMode}"
                        @login-success=${this.onStationLoginSuccess}
                        @remove-state-change-banner=${() => {
                        STORE.alertBanner.removeStateChangeBanner();
                    }}
                        @login-error=${this.stationLoginError}
                        @no-voice-options-error=${this.noVoiceOptionsError}
                        @logout-confirm="${this.onLogoutConfirm}"
                        meUserId="${STORE.agent.meUserId}"
                        organizationId="${STORE.agent.organizationId}"
                        defaultDn="${STORE.agent.defaultDn}"
                        isDefaultDnSet="${STORE.agent.isDefaultDnSet}"
                        ?isRememberMyCredentials="${STORE.agent.isRememberMyCredentials}"
                        .deviceType="${STORE.agent.deviceType}"
                        ?isWebRTCEnabled="${(STORE.app.featureFlags.isWebRTCEnabled &&
                        STORE.agent.role === SYSTEM_ROLES.AGENT) ||
                        (STORE.app.featureFlags.isWebRTCEnabled &&
                            STORE.app.featureFlags.isWebRTCSupervisorEnabled &&
                            STORE.agent.role === SYSTEM_ROLES.SUPERVISOR)}"
                        ?isDesktopAuxCodesInfiniteScrollEnabled="${STORE.app.featureFlags
                        .isDesktopAuxCodesInfiniteScrollEnabled}"
                        ?isMaerskEnabled="${STORE.app.featureFlags.isDesktopMaerskEnhancementEnabled}"
                        ?isDesktopMSStateSyncEnabled="${STORE.app.featureFlags.isDesktopMSStateSyncEnabled}"
                        ?isDesktopWebexStateSyncEnabled="${STORE.app.featureFlags.isDesktopWebexStateSyncEnabled}"
                        ?isDesktopEmergencyNotificationEnabled="${STORE.app.featureFlags
                        .isDesktopEmergencyNotificationEnabled}"
                        ?isDesktopMaerskAcqueonTeamId="${STORE.app.featureFlags.isDesktopMaerskAcqueonTeamId}"
                        ?isEmergencyModalAlreadyDisplayed="${STORE.agent.isEmergencyModalAlreadyDisplayed}"
                        @ci-logout="${() => {
                        STORE.auth.logout(STORE.auth.refreshToken);
                    }}"
                        @selected-user-role="${(e) => {
                        STORE.agent.updateSelectedAgentRole(e.detail.selectedRoleName);
                    }}"
                        @update-user-role="${(e) => {
                        STORE.agent.updateUserRole(e.detail.newUserRole);
                    }}"
                        @update-flag-user-prefs-saved="${(e) => {
                        STORE.agent.updateIsPreferenceSaved(e.detail.isPrefSaved);
                    }}"
                        @save-update-user-prefs-failed="${(e) => {
                        STORE.agent.updateIsPreferenceSavedFailed(e.detail.isPrefSaved);
                    }}"
                        @save-update-user-prefs="${(e) => {
                        STORE.app.saveOrUpdateUserPreferences(e.detail);
                    }}"
                        @update-analyser-id="${(e) => {
                        STORE.agent.updateAgentAnalyzerId(e.detail.analyzerId);
                    }}"
                        @update-user-preference-storage="${(e) => {
                        STORE.app.saveUserPreferenceInSessionStorage(e.detail);
                    }}"
                        @delete-user-prefs="${() => {
                        STORE.app.deleteUserPreferences();
                    }}"
                        @delete-user-preference-storage="${() => {
                        STORE.app.deleteUserPreferenceFromSessionStorage();
                    }}"
                        @update-device-type="${(e) => {
                        STORE.agent.updateDeviceType(e.detail.deviceType); // check need to update deviceType
                    }}"
                        @register-web-calling="${() => {
                        logger.info("[WebRtc]: registering webCalling by register-web-calling event");
                        registerWebCalling(STORE.app.domainUrl);
                    }}"
                        @aux-code-error="${() => {
                        invokeLoginErrorModal(t("app:stationLogin:auxiliaryCodeFailed"));
                    }}"
                        @acqueon-fetch-team-id-error="${() => {
                        invokeLoginErrorModal(t("app:stationLogin:fetchTeamIdFailed"));
                    }}"
                        @initiate-microsoft-auth-flow="${(e) => {
                        handleMicrosoftAuthFlow(e);
                    }}"
                      ></agentx-wc-user-station-login>
                    `
                    : this.isNoVoiceOptions
                        ? nothing
                        : html ` <agentx-wc-loading-screen></agentx-wc-loading-screen> `}
              ${this.isSessionExpired || (this.isConnectionLost && !this.isMultiLogin)
                ? html `
                    <connection-lost
                      ?isRestoreFailed=${this.isRestoreFailed}
                      ?isSessionExpired=${this.isSessionExpired}
                    ></connection-lost>
                  `
                : nothing}
              ${this.isMultiLogin ? html ` <agentx-react-multi-login-modal></agentx-react-multi-login-modal> ` : nothing}
              ${STORE.app.changeTeamLoading ? html ` <change-team-loading></change-team-loading> ` : nothing}
              ${STORE.auth.isWebexLogoutRequested
                ? html ` <agentx-wc-loading-screen></agentx-wc-loading-screen> `
                : nothing}
              ${STORE.app.changeProfileSettingsLoading
                ? html ` <user-profile-loading></user-profile-loading> `
                : nothing}
              ${this.isWebexCallingDeviceRegistered
                ? html `
                    <web-calling-widget
                      .mediaSettings=${STORE.agent.getMediaDeviceSetting}
                      .micDeviceId="${STORE.agent.selectedInputDeviceId}"
                    ></web-calling-widget>
                  `
                : nothing}
              <global-live-announcer></global-live-announcer>
            </div>
          </md-theme>
        `
            : html `
          ${this.isIframe
                ? html `
                <agentx-wc-iframe-login
                  .isLoading="${this.isrequestedIframeLogin || STORE.auth.iframeLogoutRequested}"
                  @request-iframe-login=${this.requestIframeLogin}
                ></agentx-wc-iframe-login>
              `
                : html ` <agentx-wc-loading-screen></agentx-wc-loading-screen> `}
        `;
    }
};
__decorate([
    internalProperty()
], App.prototype, "isInitialized", void 0);
__decorate([
    internalProperty()
], App.prototype, "isIframe", void 0);
__decorate([
    internalProperty()
], App.prototype, "isLoggedIn", void 0);
__decorate([
    internalProperty()
], App.prototype, "isConnectionLost", void 0);
__decorate([
    internalProperty()
], App.prototype, "isRestoreFailed", void 0);
__decorate([
    internalProperty()
], App.prototype, "isSessionExpired", void 0);
__decorate([
    internalProperty()
], App.prototype, "isrequestedIframeLogin", void 0);
__decorate([
    internalProperty()
], App.prototype, "isrequestedIframeLogout", void 0);
__decorate([
    internalProperty()
], App.prototype, "isMultiLogin", void 0);
__decorate([
    internalProperty()
], App.prototype, "cachedLayoutConfig", void 0);
__decorate([
    internalProperty()
], App.prototype, "isNotifsSuccess", void 0);
__decorate([
    internalProperty()
], App.prototype, "isWebexCallingDeviceRegistered", void 0);
__decorate([
    internalProperty()
], App.prototype, "isNoVoiceOptions", void 0);
__decorate([
    internalProperty()
], App.prototype, "messageHandler", void 0);
App = __decorate([
    customElement("agentx-app")
], App);
export { App };
