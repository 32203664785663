function equalUrls(url1, url2) {
    try {
        const parsedUrl1 = new URL(url1);
        const parsedUrl2 = new URL(url2);
        // Normalize pathname by removing trailing slashes
        // eslint-disable-next-line sonarjs/slow-regex
        const normalizePathname = (url) => url.pathname.replace(/\/+$/, "");
        return (parsedUrl1.hostname === parsedUrl2.hostname && normalizePathname(parsedUrl1) === normalizePathname(parsedUrl2));
    }
    catch (_a) {
        // If URL parsing fails, return false
        return false;
    }
}
export { equalUrls };
