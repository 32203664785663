var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { STORE } from "@/store";
import { MobxLitElement } from "@adobe/lit-mobx";
import { customElement } from "lit-element";
import { html } from "lit-html";
let AgentXQualityMonitoringComp = class AgentXQualityMonitoringComp extends MobxLitElement {
    render() {
        return html `
      <agentx-wc-quality-monitoring-grid
        .isDarkMode=${STORE.app.darkMode}
        .isLoggedIntoStation=${STORE.app.isStationLoginSuccess}
        .isQMWShowConsultRecordingsEnabled=${STORE.app.featureFlags.isQMWShowConsultRecordingsEnabled}
        .isAutoCsatEnabled=${STORE.app.featureFlags.isAutoCsatEnabled}
      ></agentx-wc-quality-monitoring-grid>
    `;
    }
};
AgentXQualityMonitoringComp = __decorate([
    customElement("agentx-wc-quality-monitoring-widget")
], AgentXQualityMonitoringComp);
export { AgentXQualityMonitoringComp };
