var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AGENT_STATE_CHANGEDBY_NAME } from "@/app/components/constants";
import { hideBanner, showBanner } from "@/app/utils/AlertBannerUtils";
import { isSupervisor, logger } from "@/app/utils/Utils";
import { UTILS_DYNAMIC } from "@/app/utils/UtilsDynamic";
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import logo from "@img/webex_cc_logo.png";
import { Router } from "@uuip/unified-ui-platform";
import { LitElement, css, customElement, property, query } from "lit-element";
import { html, nothing } from "lit-html";
import "../../components/HeaderActionItems";
import "../../components/advancedHeader/AgentStateSelectorWrapper";
import "../../components/advancedHeader/NotesWrapper";
import "../../components/advancedHeader/NotificationWrapper";
import "../../components/advancedHeader/OutdialWrapper";
import "../../components/advancedHeader/WebexWrapper";
import { debounce } from "../../utils/Utils";
import style from "./AdvancedHeaderBlock.scss";
import AgentProfileBlock from "./AgentProfileBlock";
let AgentDynamicHeader = class AgentDynamicHeader extends LitElement {
    constructor() {
        super(...arguments);
        this.advancedHeader = STORE.dynamic.advancedHeader;
        this.availableSpace = 0;
        this.DEFAULT_WIDGET_WIDTH = 150;
        this.initialLoad = false;
        this.widgetWidths = [];
        this.BUFFER_SPACE = 10;
        this.INITIALIZE_TIMEOUT = 200;
        this.ESCAPE_KEY = "Escape";
        /**
         * Gets triggered after very page resize event
         * Updates the menu bar with the count of children to be shown in header
         * vs count of children to be shown in dropdown menu
         * Compares the parent container's width as available space to the total space occupied
         * by the children <li>s
         */
        this.updateMenu = () => {
            if (this.visibleLinks) {
                this.iterateThroughMenuItems(this.visibleLinks.children);
                // Display the button accordingly
                if (this.hiddenLinks.children.length === 0) {
                    this.moreButton.classList.add("hidden");
                }
                else {
                    this.moreButton.classList.remove("hidden");
                }
                if (this.visibleLinks.children.length === 1) {
                    this.hiddenLinks.classList.add("no-items");
                }
                else {
                    this.hiddenLinks.classList.remove("no-items");
                }
                if (this.initialLoad) {
                    window.dispatchEvent(new Event("resize"));
                    this.initialLoad = false;
                }
            }
        };
    }
    static get styles() {
        return style;
    }
    connectedCallback() {
        super.connectedCallback();
        setTimeout(() => this.init(), this.INITIALIZE_TIMEOUT);
    }
    init() {
        this.initialLoad = true;
        this.addBindings();
        this.updateMenu();
    }
    /**
     * returns value for computed style as a number
     * @param widget
     * @param property
     * @returns Number
     */
    getComputedStyleWithProperty(widget, property) {
        let styleValue;
        if (widget) {
            const width = getComputedStyle(widget).getPropertyValue(property);
            styleValue = Number(width.split("px")[0]);
        }
        return styleValue;
    }
    /**
     * calculates element width along with the margin left property
     * @param widget
     * @returns total width
     */
    getElemWidth(widget) {
        let liWidth = this.getComputedStyleWithProperty(widget, "width") || 0;
        //at initial load at times widget width comes as 0, in that case take a constant value
        if (this.initialLoad && liWidth === 0) {
            liWidth = this.DEFAULT_WIDGET_WIDTH;
        }
        const liMarginLeft = this.getComputedStyleWithProperty(widget, "margin-left") || 0;
        const liMarginRight = this.getComputedStyleWithProperty(widget, "margin-right") || 0;
        return liWidth + liMarginLeft + liMarginRight;
    }
    /**
     * This method calculates the total space occupied by widgets
     * eg. wd1=10, wd2=20, wd3=30, so it'll return 60 as total occupied space
     * @param widgets
     * @returns number
     */
    getWidgetTotalOccupiedSpace(widgets) {
        let spc = 0;
        const arr = [];
        if (widgets) {
            const widgetElements = Array.from(widgets);
            widgetElements.forEach((el, i) => {
                const widgetWidth = this.getElemWidth(widgets[i]);
                spc += widgetWidth;
                arr.push(Number(spc.toFixed(2)));
            });
        }
        return arr[arr.length - 1];
    }
    /**
     * Stores the widths of <li>s being pushed to dropdown
     * Push the menu to dropdown
     * @param menuElementToPush
     */
    pushTheElementsToDropDown(menuElementToPush) {
        if (menuElementToPush) {
            this.widgetWidths.push(this.getElemWidth(menuElementToPush));
            this.hiddenLinks.insertBefore(menuElementToPush, this.hiddenLinks.firstChild);
            this.requestUpdate(); // force render widgets here so as to retain previous state
        }
    }
    /**
     * Remove the last element from widths array
     * Brings the menu item back from dropdown to menu
     * @param dropDownElementToBringBack
     */
    pushTheElementsBackToMenuBar(dropDownElementToBringBack) {
        var _a;
        if (((_a = this.hiddenLinks) === null || _a === void 0 ? void 0 : _a.firstChild) && this.visibleLinks) {
            this.widgetWidths.pop();
            this.visibleLinks.insertBefore(this.hiddenLinks.firstChild, dropDownElementToBringBack);
            this.requestUpdate(); // force render widgets here so as to retain previous state
        }
    }
    /**
     * Loops through visible links and updates menu bar and dropdown
     * @param visibleChildren
     */
    iterateThroughMenuItems(visibleChildren) {
        const visibleChildrenArray = Array.from(visibleChildren);
        visibleChildrenArray.forEach(() => {
            const vLinkWidth = this.visibleLinks && this.getComputedStyleWithProperty(this.visibleLinks, "width");
            this.availableSpace = vLinkWidth && vLinkWidth - this.BUFFER_SPACE;
            const totalSpaceNeeded = this.getWidgetTotalOccupiedSpace(visibleChildren);
            // manipulating 2nd elem of visibleLinks as 1st elem is more button
            if (this.availableSpace < totalSpaceNeeded) {
                this.pushTheElementsToDropDown(visibleChildren[1]);
            }
            else {
                // before pushing the element back check if its width would be accommodated or not
                const widthOfElemToPushBackToMenuBar = this.widgetWidths[this.widgetWidths.length - 1];
                if (this.availableSpace > totalSpaceNeeded + widthOfElemToPushBackToMenuBar) {
                    this.pushTheElementsBackToMenuBar(visibleChildren[1]);
                }
            }
        });
    }
    addBindings() {
        window.addEventListener("resize", debounce(this.updateMenu, 250));
    }
    getDynamicProperties(component) {
        switch (component.comp.toLowerCase()) {
            case "agentx-outdial":
                return Object.assign(Object.assign({}, component), { textContent: t("app:actionBar.outdial"), properties: {
                        selectedOutdialANI: "$STORE.agent.selectedOutdialANI",
                        agentStateProps: "$STORE.agent.getAgentStateProps",
                        isOutboundEnabledForTenant: "$STORE.agent.isOutboundEnabledForTenant",
                        isOutboundEnabledForAgent: "$STORE.agent.isOutboundEnabledForAgent",
                        getOutdialRegex: "$STORE.agent.getOutdialRegex",
                        dnNumber: "$STORE.agent.dnNumber",
                        outDialEp: "$STORE.agent.outDialEp",
                        isAdhocDialingEnabled: "$STORE.agent.isAdhocDialingEnabled",
                        isActiveCall: "$STORE.agentContact.isActiveCall",
                        outDialAni: "$STORE.agent.getOutDialAni",
                        addressBook: "$STORE.agent.getAddressBook",
                        isDarkModeEnable: "$STORE.app.darkMode",
                        isOutdialDisabledForExclusive: "$STORE.agentContact.shouldDisableOutdialForExclusive",
                        webRtcStatus: "$STORE.agent.webRtcStatus",
                        isWebRTCEnabled: "$STORE.app.featureFlags.isWebRTCEnabled",
                        deviceType: "$STORE.agent.deviceType",
                        isOutdialOrgSearchEnabled: "$STORE.app.featureFlags.isOutdialOrgSearchEnabled",
                        displayMicrosoftUserDetails: "$STORE.agent.collaboration.displayMicrosoftUserDetails",
                        displayWebexUserDetails: "$STORE.agent.displayWebexUserDetails",
                        microsoftAuthenticated: "$STORE.auth.getMicrosoftAuthConfig.isAuthenticated"
                    } });
            case "agentx-state-selector":
                return Object.assign(Object.assign({}, component), { properties: {
                        agentStateProps: "$STORE.agent.getAgentStateProps"
                    } });
            case "agentx-notification":
                return Object.assign(Object.assign({}, component), { textContent: t("app:actionBar.notifications"), properties: {
                        notifications: "$STORE.generalNotifications.notifications",
                        isNotificationsEnabled: "$STORE.generalNotifications.isNotificationsEnabled"
                    } });
            case "agentx-notes":
                return Object.assign(Object.assign({}, component), { textContent: t("app:notes.notes"), properties: {
                        agentId: "$STORE.agent.agentId"
                    } });
            case "agentx-webex":
                return Object.assign(Object.assign({}, component), { textContent: t("app:common.webex"), properties: {
                        webexNotificationCount: "$STORE.generalNotifications.webexNotificationCount",
                        webexEnabled: "$STORE.app.webexEnabled"
                    } });
            default:
                return Object.assign({}, component);
        }
    }
    render() {
        return html `
      <ul class="dynamicComponents">
        <li class="more">
          <md-menu-overlay size="small" position="bottom" id="menu-overlay" class="more-menu-overlay">
            <md-button
              slot="menu-trigger"
              ariaLabel="${t("app:notes.moreActions")}"
              class="more-btn"
              variant="secondary"
              size="40"
              circle
              ariaexpanded="false"
            >
              <md-icon slot="icon" class="more-icon-button" name="more-adr_16"></md-icon>
            </md-button>
            <ul class="hiddenIcons show"></ul>
          </md-menu-overlay>
        </li>
        ${STORE.dynamic.advancedHeader &&
            STORE.dynamic.advancedHeader.map((component) => {
                if ((component.comp.toLowerCase() === "agentx-outdial" && isSupervisor()) ||
                    (component.comp.toLowerCase() === "agentx-state-selector" && isSupervisor()) ||
                    (component.comp.toLowerCase() === "agentx-webex" && !STORE.app.webexEnabled)) {
                    return nothing;
                }
                else {
                    const options = this.getDynamicProperties(component);
                    return html `
              <li class="dynamic-list">
                <uuip-dynamic-widget
                  .options=${options}
                  .dataProvider=${UTILS_DYNAMIC.storeDataProvider}
                ></uuip-dynamic-widget>
                ${options.textContent
                        ? html `
                      <span aria-label="${options.textContent}" class="dynamic-list-content"
                        >${options.textContent}</span
                      >
                    `
                        : null}
              </li>
            `;
                }
            })}
      </ul>
    `;
    }
};
__decorate([
    property({ attribute: false })
], AgentDynamicHeader.prototype, "advancedHeader", void 0);
__decorate([
    query("header")
], AgentDynamicHeader.prototype, "dynamicHeader", void 0);
__decorate([
    query("ul.dynamicComponents .more")
], AgentDynamicHeader.prototype, "moreButton", void 0);
__decorate([
    query("ul.dynamicComponents")
], AgentDynamicHeader.prototype, "visibleLinks", void 0);
__decorate([
    query("ul.dynamicComponents .hiddenIcons")
], AgentDynamicHeader.prototype, "hiddenLinks", void 0);
AgentDynamicHeader = __decorate([
    customElement("agentx-dynamic-header")
], AgentDynamicHeader);
export { AgentDynamicHeader };
const renderNormalHeader = () => {
    logger.info("Header is rendered");
    return html `
    <header-action-items
      ?isoutboundenabledfortenant=${STORE.agent.isOutboundEnabledForTenant}
      ?isoutboundenabledforagent=${STORE.agent.isOutboundEnabledForAgent}
      getOutdialRegex=${STORE.agent.getOutdialRegex}
      dnNumber=${STORE.agent.dnNumber}
      outDialEp=${STORE.agent.outDialEp}
      ?isadhocdialingenabled=${STORE.agent.isAdhocDialingEnabled}
      .selectedOutdialANI=${STORE.agent.selectedOutdialANI}
      webexNotificationCount=${STORE.generalNotifications.webexNotificationCount}
      ?isactivecall=${STORE.agentContact.isActiveCall}
      ?isnotificationsenabled=${STORE.generalNotifications.isNotificationsEnabled}
      .notifications=${STORE.generalNotifications.notifications}
      @clear-notifications="${() => STORE.session.chat.clearAllUnreadMessagesFromStore()}"
      .header=${STORE.dynamic.header}
      .isCallInProgress=${STORE.agentContact.isActiveCall}
      .addressBook=${STORE.agent.getAddressBook}
      .outDialAni=${STORE.agent.getOutDialAni}
      .isDarkModeEnable=${STORE.app.darkMode}
      ?hasWidgets=${!!Object.keys(STORE.dynamic.header || {}).length}
      ?webexEnabled=${STORE.app.webexEnabled}
      .actionIcons=${STORE.dynamic.actionIcons}
      ?isOutdialDisabledForExclusive=${STORE.agentContact.shouldDisableOutdialForExclusive}
      webRtcStatus=${STORE.agent.webRtcStatus}
      .isAddressBookInfiniteScrollEnabled=${STORE.app.featureFlags.isAddressBookInfiniteScrollEnabled}
    ></header-action-items>

    ${!isSupervisor()
        ? html `
          <agentx-state-selector
            .agentStateProps="${STORE.agent.getAgentStateProps}"
            @ax-state-selected="${(e) => STORE.agent.selectState(e)}"
            @ax-banner-show="${(e) => showBanner(e)}"
            @ax-banner-hide="${() => hideBanner(AGENT_STATE_CHANGEDBY_NAME)}"
            @ax-state-selected-failed="${(e) => STORE.session.errorModal.setErrorDetails(e)}"
          ></agentx-state-selector>
        `
        : nothing}
  `;
};
const shouldRenderAdvancedHeader = () => {
    return STORE.app.featureFlags.isAdvancedHeaderEnabled && STORE.dynamic.advancedHeader;
};
const AdvancedHeader = () => {
    logger.info("Advanced Header is rendered");
    return new Router.RouterView.Block({
        template: html `
      <!-- HEADER -->
      <header class="${shouldRenderAdvancedHeader() ? "header advance" : "header"}">
        <agentx-react-app-title
          logo="${STORE.app.logo ? STORE.app.logo : logo}"
          apptitle="${STORE.app.title}"
          @ax-app-title-as-image="${(e) => {
            STORE.app.updateAppTitleAsImage(e.detail.appTitleAsImage);
        }}"
        ></agentx-react-app-title>

        ${shouldRenderAdvancedHeader()
            ? html ` <agentx-dynamic-header .advancedHeader=${STORE.dynamic.advancedHeader}></agentx-dynamic-header> `
            : renderNormalHeader()}
        ${STORE.agent.agentName && AgentProfileBlock()}
      </header>
    `,
        styles: [
            style,
            css `
        .header {
          grid-area: header; /*grid*/
        }
      `
        ]
    });
};
export default AdvancedHeader;
